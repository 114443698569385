// color variables
$white: #ffffff;
$whitish: #f7f7f7;
$black: #000000;
$dark: #333333;
$dark-orange: #ff1d21;
$orange: #ff7e31;
$purple: #2c1349;
$grey-darkest: #7a7a7a;
$grey-dark: #333333;
$grey: #666666;
$grey-light: #999999;
$grey-lightest: #eaeaea;
$facebook: #3b5998;
$twitter: #1da1f2;
$linked-in: #007ab9;
$google-plus: #dc4a38;
$instagram: #f56040;
$watsup: #08c65b;

// breakpoints
$breakpoints: (
  xs: 599px,
  sm: 768px,
  md: 1024px,
  lg: 1440px
);
