.c-profile {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  @include media(">=md") {
    margin-right: 10px !important;
  }
  &__wrap {
    position: relative;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    // cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding: 10px 0;
    padding-left: 4px;
    @include media(">=sm") {
      margin-left: 20px;
      // margin-top: 3px;
    }
    @include media(">=md") {
      padding: 16px 0;
    }
  }
  &__btn {
    position: absolute;
    z-index: 99;
    right: -10px;
    display: inline-block;
    padding: 20px 100%;
    padding-right: 0;
    background-color: transparent;
    border: none;
    margin-left: 3px;
    font-size: 9px;
    @include media(">=sm") {
      margin-left: 0;
    }
    @include media(">=sm") {
      font-size: 12px;
    }
    &::before {
      display: inline-block;
    }
    &.show {
      &::before {
        transform: rotate(180deg);
      }
    }
  }
  &__name {
    background-color: $purple;
    border: 3px solid $grey-lightest;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    @include media("<=xs") {
      background-color: white;
      border-color: $purple;
    }
    @include media(">=sm") {
      width: 36px;
      height: 36px;
      border-width: 4px;
    }
    &-t {
      margin-top: 1px;
      color: white;
      font-size: 10px;
      font-family: $poppins-bold;
      @include media("<=xs") {
        color: $purple;
      }
      @include media(">=sm") {
        font-size: 12px;
      }
    }
  }
}
