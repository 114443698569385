.cart {
  &__item {
    @include box-shadow;
    position: relative;
    background-color: $whitish;
    padding: 10px;
    margin: 24px auto;
    @include media("<=xs") {
      margin: 24px 10px;
      padding-bottom: 0;
    }
    &-avatar {
      width: 64px;
      height: 64px;
      @include media(">=sm") {
        width: 70px;
        height: 70px;
      }
      @include media(">=md") {
        width: 95px;
        height: 95px;
      }
      img {
        width: 100%;
      }
    }
    &-date {
      background-color: white;
      padding: 5px;
      transform: rotate(-90deg);
      transform-origin: left;
      position: absolute;
    }
    &-wrap {
      display: flex;
      @include media("<=xs") {
        flex-wrap: wrap;
      }
      @include media(">=md") {
        align-items: center;
      }
    }
    .cart__item-remove {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    &-remove {
      cursor: pointer;
      width: 22px;
      height: 22px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
      @include media(">=sm") {
        background-size: 10px;
        &:hover {
          background-color: darken(#fff, 5);
        }
      }
      @include media(">=md") {
        width: 30px;
        height: 30px;
      }
    }
    &-view {
      cursor: pointer;
      text-decoration: none;
      position: absolute;
      right: 20px;
      bottom: 15px;
      font-size: 12px;
      color: $dark-orange;
      text-transform: uppercase;
      @include media("<=xs") {
        top: 48%;
        bottom: unset;
      }
      @include media(">=sm") {
        &:hover {
          color: darken($dark-orange, 10);
        }
        font-size: 14px;
      }
    }
  }
  &__flex {
    padding: 0 12px;
    @include media("<=xs") {
      padding: 0;
    }
    @include media(">=md") {
      margin: 0;
    }
    &-1 {
      // flex-shrink: auto;
      padding: 0;
      position: relative;
    }
    &-2 {
      padding-left: 16px;
      @include media(">=sm") {
        max-width: 200px;
        word-break: break-word;
      }
      @include media(">=md") {
        flex: 1;
      }
      .h {
        font-family: $poppins-bold;
        font-size: 14px;
        color: $purple;
        margin-bottom: 8px;
        @include media(">=sm") {
          margin-bottom: 12px;
        }
        @include media(">=md") {
          font-size: 16px;
        }
      }
      .d {
        color: $grey;
        font-size: 12px;
        margin-bottom: 8px;
        @include media(">=sm") {
          margin-bottom: 6px;
        }
        @include media(">=md") {
          font-size: 14px;
        }
      }
      .c {
        color: $grey;
        font-family: $poppins-semibold;
        font-size: 12px;
        @include media(">=md") {
          font-size: 14px;
        }
      }
    }
    &-3 {
      span {
        color: $grey;
        font-size: 13px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        @include media(">=sm") {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
        @include media(">=md") {
          font-size: 14px;
        }
      }
      @include media("<=xs") {
        margin: 16px 0;
        width: calc(100% + 30px) !important;
        background-color: white;
        padding: 15px 20px;
        margin-bottom: 0;
        margin-left: -10px;
        margin-right: -10px;
      }
      // @include media("<sm") {
      //   width: 100%;
      // }
      @include media(">=sm") {
        margin: 0;
        max-width: 220px;
        word-break: break-word;
      }
      @include media(">=md") {
        flex: 1;
      }
    }
    &-4 {
      // flex: 1;
      @include media("<=xs") {
        width: calc(100% + 30px) !important;
        background-color: white;
        padding: 0 20px;
        margin-left: -10px;
        margin-right: -10px;
      }
      .e {
        color: $purple;
        font-family: $poppins-bold;
        font-size: 15px;
        margin-bottom: 8px;
        @include media(">sm") {
          margin-bottom: 10px;
        }
        @include media(">=md") {
          font-size: 18px;
        }
      }
      .t {
        color: $dark-orange;
        font-size: 10px;
        @include media(">=md") {
          font-size: 12px;
        }
      }
      @include media("<=sm", ">xs") {
        .cart__flex-5 {
          margin-top: 6px;
          display: block !important;
        }
      }
    }
    &-5 {
      @include media("<=xs") {
        margin-top: 0 !important;
        text-align: right;
        background-color: white;
        width: calc(100% + 30px) !important;
        margin-left: -10px;
        margin-right: -10px;
        padding: 0 20px 15px;
      }
      @include media("<=sm", ">xs") {
        display: none;
      }
      @include media("<sm") {
        margin-top: 16px;
        width: 100%;
      }
      @include media(">=md") {
        flex: 1;
      }
      span {
        color: $purple;
        font-size: 13px;
        @include media("<=xs") {
          margin-top: -5px !important;
        }
        @include media(">=md") {
          font-family: $poppins-bold;
          font-size: 16px;
        }
      }
    }
    &-2,
    &-3,
    &-4 {
      @include media(">=sm") {
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid #e0e0e0;
      }
      @include media("<=sm", ">xs") {
        min-width: 180px;
      }
    }
    @include media("<=sm", ">xs") {
      &-4 {
        border-right: 0;
      }
    }
    > span {
      display: block;
    }
  }
}
