.profile {
  &__box {
    margin-bottom: 5px;
    flex: 1 0 50%;
    -webkit-flex: 1 0 50%;
    @include media(">=md") {
      max-width: 50%;
    }
    &-inner {
      display: block;
      text-decoration: none;
      position: relative;
      background-color: white;
      margin: 12px 26px;
      padding: 8px;
      z-index: 2;
      > span {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
      }
      @include media(">=sm") {
        margin: 10px;
        padding: 11px;
      }
      @include media(">=md") {
        &:hover {
          background-color: darken($color: #fff, $amount: 1);
          .profile__head {
            background-color: darken($whitish, 1);
          }
        }
      }
      @include box-shadow;
      .profile__foot {
        padding: 10px 10px 5px;
        > span {
          display: block;
          color: $grey;
          font-size: 13px;
          line-height: 1.3;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
        @include media(">=sm") {
          padding: 20px 16px 10px;
          > span {
            font-size: 14px;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  &__head {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    border-bottom: 1px solid #dadada;
    @include media(">=sm") {
      flex-wrap: nowrap;
      border: none;
    }
    &-l {
      background-color: $whitish;
      padding: 10px;
      flex: 100%;
      display: flex;
      align-items: center;
      span {
        color: $grey;
        display: block;
      }
      @include media(">=sm") {
        flex: 5;
      }
    }
    &-avatar {
      display: inline-block;
      background-color: white;
      width: 71px;
      height: 71px;
      border-radius: 50%;
      margin-right: 10px;
      img {
        width: 100%;
      }
      @include media(">=sm") {
        margin-right: 15px;
        width: 86px;
        height: 86px;
      }
    }
    &-r {
      flex: 3;
      padding: 10px;
      > span {
        display: block;
        color: $grey;
        font-size: 13px;
        line-height: 1.3;
        &:first-child {
          margin-top: 2px;
        }
        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }
      @include media(">=sm") {
        background-color: $whitish;
        > {
          span {
            font-size: 14px;
            &:first-child {
              margin-top: 5px;
            }
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  // profile inner styles
  &__name {
    color: $purple !important;
    font-family: $poppins-bold;
    font-size: 14px;
    margin-bottom: 6px;
    @include media(">=sm") {
      margin-bottom: 12px;
      font-size: 16px;
    }
  }
  &__des {
    line-height: 1.3;
    margin: 4px 0;
    font-size: 13px;
    @include media(">=sm") {
      font-size: 14px;
      margin: 8px 0;
    }
  }
  &__com {
    font-family: $poppins-semibold;
    font-size: 13px;
    @include media(">=sm") {
      font-size: 14px;
    }
  }
}
