@charset "UTF-8";

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 4;
  top: 50%;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: white;
  color: transparent;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: 1px solid $dark-orange;
  outline: none;
  @include media("<=sm") {
    transform: scale(0.8);
    top: 1.6rem;
  }

  &:hover,
  &:focus {
    outline: none;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }

  &:before {
    content: "\e90a";
    position: absolute;
    left: 50%;
    top: 50%;
    color: $dark-orange;
    font-family: "icomoon" !important;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  @include media(">=sm") {
    left: 0;
  }
  @include media("<=sm") {
    left: auto;
    right: 72px;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

.slick-next {
  right: 0;
  [dir="rtl"] & {
    left: 50px;
    right: auto;
  }
  &::before {
    transform: translate(-50%, -50%);
  }
  @include media("<=sm") {
    right: 30px;
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
