.cart {
  &__container {
    padding-top: 60px;
    padding-bottom: 40px;
    @include media("<=xs") {
      padding-top: 0;
      padding-bottom: 60px;
    }
    @include media(">=sm") {
      padding-top: 67px;
    }
    @include media(">=md") {
      padding-top: 80px;
    }
  }
  &__head {
    display: flex;
    flex-direction: column;
    padding: 30px 0 15px;
    @include media("<=xs") {
      padding: 20px 0 15px;
      &-c {
        padding: 0;
      }
    }
    @include media(">=sm") {
      padding: 50px 0 30px;
      flex-direction: row;
      align-items: flex-start;
    }
    @include media(">=md") {
      padding: 60px 0 30px;
    }
    &-l {
      flex: 1;
      margin-bottom: 10px;
      @include media("<=xs") {
        display: none;
      }
      @include media(">=md") {
        margin-bottom: 0;
      }
      p {
        margin: 0;
        @include media("<sm") {
          font-size: 14px;
        }
      }
      &h {
        font-family: $poppins-bold;
        font-size: 18px !important;
        color: $purple;
        margin-bottom: 8px !important;
        @include media(">=sm") {
          margin-bottom: 12px !important;
          font-size: 24px !important;
        }
        @include media(">=md") {
          font-size: 28px !important;
        }
      }
    }
    &-r {
      &-h {
        display: flex;
        align-items: flex-end;
        @include media(">=sm") {
          align-items: center;
        }
      }
      &-c {
        @include media("<=xs") {
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 9;
          display: block;
          width: 100%;
          background: $purple;
          padding: 12px 25px;
          display: flex;
          align-items: center;
          .btn--purple {
            background-color: white;
            color: $purple;
            margin-right: 20px;
          }
          .inline-checkbox {
            font-size: 12px;
            color: white;
            margin: 0;
            flex: 1;
          }
        }
      }
    }
  }
  &__messages {
    margin: 30vh auto;
    text-align: center;
  }
}

.inline-checkbox {
  color: #6a6a6a;
  display: block;
  margin-top: 10px;
  position: relative;
  display: flex;
  @include media(">=sm") {
    margin-top: 15px;
  }
  input,
  label {
    cursor: pointer;
    line-height: 1.3;
  }
  input {
    opacity: 0;
    z-index: 3;
    &:checked + span {
      border-color: $dark-orange;
      &::after {
        opacity: 1;
      }
    }
    @include media("<=xs") {
      margin-top: 2px;
    }
  }
  span {
    position: relative;
    // left: -2px;
    width: 12px;
    height: 12px;
    display: inline-block;
    border: 1px solid #6a6a6a;
    transform: translateX(-11px);
    z-index: 1;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $dark-orange;
      // transition: opacity 0.3s;
      opacity: 0;
    }
    @include media("<=xs") {
      margin-top: 2px;
    }
  }
  @include media(">=sm") {
    span {
      width: 13px;
      height: 13px;
    }
  }
}
