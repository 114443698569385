.acc {
  &__container {
    padding-top: 60px;
    @include media("<=xs") {
      padding-top: 0;
    }
    @include media(">=sm") {
      padding-top: 67px;
    }
    @include media(">=md") {
      padding-top: 80px;
    }
  }
  &__detail {
    &-head {
      padding: 36px 0;
      background-color: $purple;
      color: $white;
      @include media(">=sm") {
        padding: 40px 0;
      }
      > .container {
        display: flex;
        @include media("<=xs") {
          align-items: center;
        }
      }
      &-avatar {
        width: 58px;
        height: 58px;
        margin-right: 8px;
        background-color: $white;
        border: 4px solid $dark-orange;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: $purple;
          font-size: 22px;
          font-family: $poppins-semibold;
        }
        @include media(">=sm") {
          width: 90px;
          height: 90px;
          margin-right: 14px;
          border-width: 7px;
          span {
            font-size: 36px;
          }
        }
      }
      &-name {
        flex: 1;
      }
      &-h {
        display: block;
        font-family: $poppins-bold;
        font-size: 16px;
        margin-bottom: 8px;
        @include media(">=sm") {
          font-size: 22px;
          margin-bottom: 12px;
        }
      }
      &-d {
        display: block;
        font-size: 13px;
        @include media(">=sm") {
          font-size: 18px;
        }
      }
      &-l {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
    &-body {
      padding-bottom: 30px;
      > .container {
        @include media(">=md") {
          display: flex;
        }
      }
      &-flex {
        position: relative;
        flex: 1;
        margin: 20px 0;
        @include media(">=sm") {
          margin: 30px 15px;
        }
        @include box-shadow;
        .wrap {
          width: 100%;
          tr {
            width: 100%;
            word-break: break-all;
            td {
              @extend .p;
              font-size: 14px;
              padding: 10px;
              padding-left: 12px;
              &:first-child {
                width: 45%;
                color: $purple;
              }
              @include media(">=sm") {
                font-size: 16px;
                padding: 17px;
                padding-left: 40px;
              }
            }
            &:first-child {
              td {
                padding-top: 16px;
                @include media(">=sm") {
                  padding-top: 40px;
                }
              }
            }
            &:last-child {
              td {
                padding-bottom: 16px;
                @include media(">=sm") {
                  padding-bottom: 40px;
                }
              }
            }
          }
        }
        &:after {
          content: "";
          position: absolute;
          width: 44%;
          background-color: $whitish;
          height: 100%;
          left: 0;
          top: 0;
          z-index: -1;
        }
      }
    }
    &-edit {
      font-size: 11px;
      text-decoration: none;
      color: white;
      @include media(">=sm") {
        font-size: 14px;
      }
      > span:first-child {
        background-color: #fff;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 8px;
        img {
          width: 15px;
        }
        @include media(">=sm") {
          width: 33px;
          height: 33px;
          img {
            width: 17px;
          }
        }
      }
    }
  }
}
