.footer {
  overflow-x: hidden;
  background: $white;
  &__container {
    @extend .clearfix;
    padding: 30px 25px;
    @include media(">=md") {
      padding: 35px;
    }
  }
  &__nav {
    margin-top: 30px;
    @include media(">=md") {
      float: left;
      margin-top: 0;
    }
    .footer__social-icons {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      li {
        display: inline-block;
        padding: 0 15px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          border: 1px solid $grey-light;
          text-decoration: none;
          font-size: 16px;
          @include transition;
          &.fb {
            > i:before {
              color: $facebook;
            }
          }
          &.twitter {
            > i:before {
              color: $twitter;
            }
          }
          &.linked-in {
            > i:before {
              color: $linked-in;
            }
          }
          &.instagram {
            > i:before {
              color: $instagram;
            }
          }
          @include media(">=md") {
            &:hover {
              > i:before {
                color: $white;
              }
              border-color: transparent;
              &.fb {
                background: $facebook;
              }
              &.twitter {
                background: $twitter;
              }
              &.linked-in {
                background: $linked-in;
              }
              &.instagram {
                background: $instagram;
              }
            }
          }
        }
      }
    }
  }
  &__legal-container {
    color: $grey-darkest;
    @extend .p;
    text-align: center;
    @include media(">=md") {
      float: right;
    }
    p {
      margin: 0;
      display: inline-block;
      &:nth-child(2) {
        &:before {
          content: "/";
          position: relative;
          padding: 5px;
        }
      }
      @include media(">=sm") {
        &:not(:nth-child(1)) {
          &:before {
            content: "/";
            position: relative;
            padding: 5px;
          }
        }
      }
      &:last-child() {
        margin-top: 10px;
      }
      a {
        color: inherit;
        transition: all 0.3s;
        text-decoration: none;
        &:hover {
          color: $grey-dark;
        }
      }
    }
  }
  &__navigation {
    background: $whitish;
    font-family: $poppins-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    &-sub {
      background-color: $grey-lightest;
      padding: 36px 40px;
      p {
        font-family: inherit;
        font-size: 17px;
        color: $grey-darkest;
        margin: 0;
        margin-bottom: 1.5rem;
        line-height: 30px;
        @include media("<=md") {
          font-size: 15px;
        }
      }
      .subscribe {
        display: flex;
        padding: 6px;
        background-color: $white;
        &-input {
          padding: 10px 16px 8px;
          flex: 1;
          border: none;
          font-size: 16px;
          color: $grey;
          margin: 0 !important;
          height: unset !important;
          line-height: unset !important;
          &:focus,
          &:active {
            outline: none;
          }
          &::placeholder {
            color: $grey-light;
          }
          @include media("<=md") {
            padding: 8px 6px 6px;
            font-size: 14px;
          }
        }
        &-button {
          background: $white;
          color: $dark-orange;
          align-items: center;
          justify-content: center;
          padding: 8px 14px 5px;
          border: 1px solid lighten($dark-orange, 20);
          font-size: 12px;
          @include transition;
          &:hover {
            background: $dark-orange;
            color: $white;
          }
        }
      }
    }
    &-links {
      padding: 40px;
      @include media("<=sm") {
        padding: 20px 16px;
      }
      ul {
        margin: 0;
        padding: 0;
        flex-wrap: wrap;
        li {
          display: block;
          padding: 10px 0;
          a {
            display: inline-block;
            color: $grey;
            font-family: inherit;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            padding: 10px;
            text-decoration: none;
            @include media("<=sm") {
              font-size: 14px;
              padding-left: 25px;
            }
            @include media("<sm") {
              padding-left: 20px !important;
              padding-right: 0 !important;
            }
            @include media(">=md") {
              font-size: 16px;
            }
            @include transition;
            &:hover {
              color: darken($grey, 20);
            }
          }
        }
      }
    }
  }
  @include media("<=xs") {
    &__navigation {
      display: flex;
      flex-direction: column;
      &-sub {
        order: 2;
        .subscribe {
          width: 100% !important;
        }
      }
      &-links {
        order: 1;
      }
    }
  }
}
