.profile {
  &__container {
    padding-top: 60px;
    @include media("<=xs") {
      padding-top: 0;
    }
    @include media(">=sm") {
      padding-top: 67px;
    }
    @include media(">=md") {
      padding-top: 80px;
    }
  }
}
