.profile {
  &__list {
    &-pagination {
      padding-bottom: 60px;
      @include media(">=sm") {
        text-align: right;
      }
      &--wrap {
        @include box-shadow;
        display: inline-flex;
        background: white;
        color: #7c7c7c;
        align-items: center;
        padding: 6px 0;
        margin: 0 10px;
        button {
          cursor: pointer;
          background: transparent;
          border: none;
          outline: none;
          font-size: 12px;
          display: flex;
          padding: 8px;
          @include media(">=sm") {
            font-size: 14px;
            padding: 8px;
          }
          span[class^="icon-"] {
            margin: 0 10px;
            color: $dark-orange;
          }
          &.prev {
            span[class^="icon-"] {
              transform: rotate(180deg);
            }
          }
          &:hover {
            color: #484848 !important;
          }
        }
        .page {
          display: flex;
          list-style: none;
          padding: 0;
          margin: 0 10px;
          @include media(">=sm") {
            margin: 0 28px;
          }
          li {
            margin: 0 6px;
            a {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 23px;
              height: 23px;
              padding-top: 2px;
              color: inherit;
              text-decoration: none;
              border-radius: 4px;
              &.active {
                color: white !important;
                background-color: $dark-orange !important;
              }
              &:hover {
                background-color: rgb(238, 238, 238);
              }
            }
          }
        }
      }
    }
  }
}
