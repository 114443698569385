.header {
  width: 100%;
  position: fixed;
  z-index: 6;
  background: $white;
  box-shadow: 0px 0px 6px 0px #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 0;
  @include media("<=xs") {
    position: static;
  }
  &__wrap {
    display: flex;
    align-items: center;
  }
  .brand {
    padding: 0;
    .logo {
      display: flex;
      align-items: flex-end;
      &__img {
        display: inline-block;
        text-decoration: none;
        background-repeat: no-repeat;
        background-size: contain;
        width: 112px;
        min-height: 24px;
        @include media(">=sm") {
          width: 130px;
          min-height: 32px;
        }
        @include media(">=md") {
          width: 158px;
          min-height: 40px;
        }
      }
      &__text {
        color: $grey;
        line-height: 1.4;
        opacity: 1;
        font-size: 14px;
        z-index: -1;
        @include media(">=md") {
          font-size: 16px;
        }
        @include media("<=xs") {
          line-height: 1;
          font-size: 12px;
        }
      }
    }
  }
  .nav {
    padding: 0;
    text-align: right;
    &-m {
      text-align: left;
      .c-profile__wrap {
        margin-left: 0;
      }
      .c-profile__dropdown {
        left: -2px;
        right: unset;
      }
    }
    @include media("<sm") {
      width: auto;
      flex: 1;
    }
    &__list {
      position: relative;
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-flex;
      align-items: center;
      &-m {
        > li {
          padding: 4px 0 !important;
        }
      }
      > li {
        display: inline-block;
        padding: 8px 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        @include media(">=sm") {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
        @include media(">=md") {
          padding: 15px 0;
        }
      }
      .cart-item a strong {
        font-family: $poppins-bold !important;
      }
      &-item {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;
        line-height: 1;
        color: $grey;
        font-size: 14px;
        font-family: $poppins-regular;
        padding: 6px 8px;
        @include media(">=sm") {
          padding: 6px;
          margin-top: 5px;
        }
        @include media(">=md") {
          font-size: 15px;
          &:hover {
            color: darken($grey, 20);
          }
        }
        span[class^="icon-"] {
          font-size: 22px;
          @include media(">=sm") {
            margin-right: 8px;
          }
        }
      }
      &-notification {
        position: absolute;
        top: -4px;
        left: 14px;
        background-color: $dark-orange;
        color: #fff;
        font-family: $poppins-medium;
        font-size: 10px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2px;
        opacity: 0;
        animation: opac 0.3s forwards;
        @include media(">=sm") {
          top: -6px;
          left: 12px;
          font-size: 12px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  // purple header styles
  @include media("<=xs") {
    transition: background-color 0.1s;
    &-purple {
      background-color: $purple;
      span[class^="icon-"]::before {
        color: white !important;
      }
      .c-profile {
        &__name {
          background-color: transparent;
          border-color: white !important;
          &-t {
            color: white;
          }
        }
        &__btn {
          color: white;
        }
      }
    }
  }
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
