.profile {
  &__filter {
    background: #fff;
    padding: 35px 0 20px;
    @include media("<=xs") {
      padding: 20px 0 12px;
    }
    @include media(">=sm") {
      padding: 45px 0;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      @include media(">=md") {
        align-items: center;
        flex-direction: row;
      }
      &-l {
        flex: 1;
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        @include media("<=xs") {
          margin-bottom: 10px;
        }
        @include media(">=sm") {
          margin-bottom: 25px;
        }
        @include media(">=md") {
          margin-bottom: 0;
        }
      }
      &-r {
        display: flex;
        align-items: center;
        @include media("<=xs") {
          padding: 0 10px;
          .inline-checkbox {
            margin-top: 5px;
          }
        }
        > span {
          margin-top: 0;
          &:not(:last-child) {
            margin-right: 10px;
            @include media(">=sm") {
              margin-right: 30px;
            }
          }
        }
        > div {
          flex: 1;
        }
      }
      button.btn {
        display: inline-flex;
        align-items: center;
        padding: 0.67rem 1.5rem;
        margin-left: 10px;
        height: 36px;
        @include media(">=sm") {
          padding: 0.67rem 3.5rem;
          margin-left: 30px;
          height: 38px;
        }
        @include media(">=md") {
          font-size: 16px;
        }
        img {
          margin-right: 10px;
        }
      }
    }
    &-options {
      background-color: #efefef;
      padding: 8px 0;
      .container {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          padding: 2px;
          color: $dark-orange;
          font-size: 12px;
          @include media(">=sm") {
            font-size: 14px;
            padding: 6px;
          }
        }
        .option-wrap {
          flex: 1;
          padding-right: 10px;
          .option {
            padding-left: 8px;
            padding-right: 8px;
            margin: 4px 0;
            display: inline-block;
            border-right: 1px solid $dark-orange;
            &:last-child {
              border-right: 0;
            }
          }
          @include media(">=sm") {
            padding-right: 30px;
            .option {
              padding-left: 16px;
              padding-right: 16px;
            }
          }
        }
        .clear-btn {
          span {
            color: $purple;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }
    }
  }
}

input[type="text"].filter_input {
  width: 320px;
  background-color: #f4f4f4;
  border: #f4f4f4;
  margin: 0;
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: 98% center;
  padding-right: 40px;
  &-m {
    width: 100%;
  }
  @include media(">=sm") {
    font-size: 16px;
  }
}
