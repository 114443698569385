.profile {
  &__s {
    &-head {
      background-color: $purple;
      color: $white;
      padding: 26px 0;
      @include media("<=xs") {
        padding: 26px;
      }
      @include media(">=sm") {
        padding: 30px 0;
      }
      @include media(">=md") {
        padding: 45px 0;
      }
      &-wrap {
        display: flex;
        flex-direction: column;
        @include media("<=xs") {
          padding: 0;
        }
        @include media(">=sm") {
          align-items: center;
          flex-direction: row;
        }
      }
      &-l {
        display: flex;
        flex: 70%;
        margin-bottom: 20px;
        @include media("<=xs") {
          margin-bottom: 10px;
          padding-left: 25px;
        }
        @include media(">=sm") {
          min-height: 100px;
          margin-bottom: 0;
        }
        @include media(">=md") {
          flex: 1;
        }
        // align-items: center;
        &.breakpoint__sm-down {
          padding-left: 0;
          display: block;
        }
        span {
          line-height: 1.2;
        }
        .name {
          display: block;
          font-family: $poppins-bold;
          font-size: 18px;
          margin-bottom: 6px;
          @include media(">=sm") {
            margin-bottom: 12px;
          }
          @include media(">=md") {
            font-size: 22px;
          }
        }
        .des,
        .com {
          display: block;
          font-size: 14px;
          margin: 5px 0;
          @include media(">=sm") {
            margin: 2px 0;
          }
          @include media(">=md") {
            font-size: 16px;
          }
        }
        .com {
          font-family: $poppins-semibold;
        }
        .cost {
          display: block;
          font-family: $poppins-bold;
          font-size: 18px;
          margin: 6px 0 4px;
          @include media(">=sm") {
            margin: 14px 0 7px;
          }
          @include media(">=md") {
            font-size: 20px;
          }
          &-exl {
            font-size: 12px;
            color: $dark-orange;
            opacity: 0.9;
            @include media(">=md") {
              font-size: 13px;
            }
          }
        }
      }
      &-r {
        margin-top: 14px;
        @include media("<=xs") {
          text-align: center;
        }
        @include media(">=sm") {
          flex: 30%;
          text-align: right;
          margin-top: 0;
        }
        @include media(">=md") {
          text-align: left;
          flex: unset;
        }
        > span {
          display: block;
          margin-top: 6px;
          font-size: 12px;
          @include media(">=sm") {
            margin-top: 12px;
          }
          @include media(">=md") {
            font-size: 14px;
            margin-top: 18px;
          }
        }
        > .btn {
          margin-right: 10px;
          &:first-child {
            margin-bottom: 8px;
            @include media(">=md") {
              margin-bottom: 0;
            }
          }
          @include media(">=sm") {
            margin-right: 0;
          }
        }
        .btn {
          &:not(:last-child) {
            @include media(">=md") {
              margin-right: 20px;
            }
          }
          &--hollow {
            cursor: pointer;
            appearance: none;
            background-color: transparent;
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: 94% center;
            padding: 1rem 3rem 1rem 2rem;
            margin-bottom: 8px;
            margin-right: 10px;
            &:hover {
              color: white !important;
            }
            @include media(">=sm") {
              background-size: 12px;
              margin-right: 0;
              padding: 1.2rem 3.2rem 1.2rem 2.2rem;
            }
            @include media(">=md") {
              margin-bottom: 0;
              padding: 1.6rem 5rem 1.6rem 2.4rem;
            }
            option {
              font-family: $poppins-regular;
              color: black;
            }
          }
        }
        &-ins {
          > span {
            display: block;
            margin-top: 6px;
            font-size: 12px;
            @include media(">=sm") {
              margin-top: 14px;
            }
            @include media(">=md") {
              font-size: 14px;
              margin-top: 18px;
            }
            &.negotiate {
              font-size: 12px;
              position: relative;
              display: inline-flex;
              margin-top: 14px;
              @include media(">=sm") {
                font-size: 14px;
              }
              input,
              label {
                cursor: pointer;
              }
              input {
                opacity: 0;
                z-index: 3;
                &:checked + span {
                  border-color: $dark-orange;
                  &::after {
                    opacity: 1;
                  }
                }
              }
              span {
                position: relative;
                left: -2px;
                width: 12px;
                height: 12px;
                display: inline-block;
                border: 1px solid #fff;
                transform: translateX(-11px);
                z-index: 1;
                &::after {
                  position: absolute;
                  content: "";
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  background-color: $dark-orange;
                  // transition: opacity 0.3s;
                  opacity: 0;
                }
                @include media(">=sm") {
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }
        }
      }
    }
    &-avatar {
      margin-right: 16px;
      img {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        border: 4px solid #d5d5d5;
      }
      @include media(">=sm") {
        img {
          width: 70px;
          height: 70px;
          border-width: 6px;
        }
      }
      @include media(">=md") {
        margin-right: 25px;
        img {
          width: 98px;
          height: 98px;
        }
      }
    }
    &-body {
      padding: 25px 15px;
      @include media(">=sm") {
        padding: 55px 15px;
      }
      .p-body {
        &__wrap {
          width: 100%;
          border: 1px solid #dadada;
          padding: 20px;
          margin-bottom: 16px;
          @include media(">=sm") {
            padding: 28px;
            margin-bottom: 20px;
          }
          @include media(">=md") {
            padding: 35px;
            margin-bottom: 26px;
          }
          p {
            margin: 0;
          }
        }
        &__h {
          font-family: $poppins-bold;
          font-size: 16px;
          color: $purple;
          @include media(">=sm") {
            font-size: 18px;
          }
        }
        &__b {
          margin-top: 18px;
          @include media(">=sm") {
            display: flex;
            margin-top: 28px;
          }
          @include media(">=md") {
            margin-top: 35px;
          }
          &-f {
            flex: 1;
            @include media(">=sm") {
              &:nth-child(2) {
                padding-left: 30px;
              }
            }
            @include media("<=sm") {
              &:nth-child(1) {
                padding-bottom: 16px;
              }
            }
            ul {
              @extend .p;
              margin: 0;
              padding: 0;
              list-style: none;
              li {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.animateInput {
  animation: anim 0.3s forwards;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-6px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
