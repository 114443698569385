.signform {
  &__container {
    padding-top: 60px;
    padding-bottom: 0;
    min-height: 100vh;
    display: flex;
    @include media("<=xs") {
      padding-top: 0;
    }
    @include media(">=sm") {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
  &__wrap {
    width: 100%;
    padding: 40px 0;
    @include media(">=sm") {
      padding: 100px 0 40px;
    }
  }
  &__btn {
    text-align: center;
    .btn {
      margin: 5px;
    }
  }
  &__info {
    background-color: $whitish;
    padding: 25px;
    margin: 22px 10px;
    text-align: center;
    max-width: 974px;
    @include media(">=sm") {
      padding: 45px;
      margin: 45px auto;
    }
    &-heading {
      font-family: $poppins-bold;
      font-size: 18px;
      margin: 0;
      margin-bottom: 26px;
      @include media(">=sm") {
        font-size: 22px;
        margin-bottom: 35px;
      }
    }
    &-para {
      margin: 0;
    }
  }
}
