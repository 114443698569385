.slider-contain {
  background: white;
  padding: 5.6rem 0;
  .container {
    position: relative;
  }
  .slider {
    &-single {
      // padding: 0 5%;
      .container {
        padding-left: 4%;
        padding-right: 4%;
        .slider {
          padding: 0 2rem;
          &-img {
            @include media("<=sm") {
              margin-bottom: 5rem;
              text-align: left;
            }
            text-align: center;
            &__wrap {
              background-color: $purple;
              display: inline-block;
              img {
                max-width: 100%;
                margin: 0 auto;
                position: relative;
                top: 1rem;
                left: 1rem;
              }
            }
          }
          &-content {
            font-family: $poppins-regular;
            text-align: left;
            position: relative;
            &__name {
              margin: 0 0 1rem;
              font-size: 26px;
              line-height: 1;
              color: #666666;
            }
            &__designation {
              font-size: 20px;
              font-family: inherit;
              color: #6b6b6b;
            }
            &__company-name {
              display: inline-block;
              margin-top: 1rem;
              font-size: 18px;
              font-weight: 600;
              color: $grey-light;
            }
            p {
              margin: 2rem 0;
              font-size: 16px;
              font-family: inherit;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              color: #8d8d8d;
            }
          }
        }
      }
    }
    &-video {
      &__wrapper {
        display: flex;
        align-items: center;
      }
      &__icon {
        cursor: pointer;
        width: 45px;
        height: 45px;
        margin-right: 16px;
        img {
          width: 100%;
        }
      }
    }
  }

  &.home-slider {
    padding: 6rem 0;
    background: #f4f4f4;
    .slider {
      &-single {
        .container {
          .slider-content {
            @include media(">=md") {
              padding-left: 4.5rem;
            }
            p {
              @include media(">=sm") {
                max-width: 440px;
                padding-right: 3rem;
              }
            }
            .slider-video__wrapper {
              margin-top: 0.9rem;
              margin-bottom: 3.2rem;
            }
            &__company-name {
              margin-top: 2rem;
              @include media("<=sm") {
                position: static;
                max-width: 210px;
              }
            }
          }
          .slider-img__wrap img {
            top: 2rem;
            left: 2rem;
          }
          .watermark {
            position: absolute;
            right: 0;
            transform: translateY(-50%);
            font-family: "poppinslight", "poppins";
            font-size: 26px;
            color: #c6c6c6;
            transform: rotate(-90deg);
            transform-origin: center;
            top: 25%;
            @include media(">=md") {
              top: 40%;
            }
            @include media("<sm") {
              visibility: hidden;
            }
          }
        }
      }
      .slick-arrow {
        top: 93% !important;
        background: #f4f4f4 !important;
        &.slick-prev {
          left: unset !important;
          right: 60px !important;
          @include media(">=sm") {
            right: 145px !important;
          }
          @include media(">=md") {
            right: 190px !important;
          }
        }
        &.slick-next {
          right: 15px !important;
          @include media(">=sm") {
            right: 95px !important;
          }
          @include media(">=md") {
            right: 136px !important;
          }
        }
      }
    }
  }

  &.brand-logo {
    background: white;
    @include media("<sm") {
      padding: 1.8rem 0;
    }
    > .container {
      padding-right: 10%;
      padding-left: 10%;
    }
    .logo-single {
      max-width: 120px;
      @include media(">=sm") {
        max-width: 145px;
      }
      @include media(">=md") {
        max-width: 180px;
      }
      margin: 0 auto;
    }
    .slick-slide {
      > div {
        text-align: center;
      }
    }

    .slick-arrow {
      background: white !important;
      @include media("<=sm") {
        top: 38% !important;
      }
      @media (max-width: 599px) {
        visibility: hidden;
      }
      &.slick-prev {
        left: -50px !important;
        @include media("<=sm") {
          left: -30px !important;
        }
      }
      &.slick-next {
        right: -50px !important;
        @include media("<=sm") {
          right: -30px !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .slider-contain .slider-single .container {
    .slider {
      padding: 0;
      &-img {
        margin-bottom: 3rem;
        text-align: left;
      }
      &-content {
        &__name {
          font-size: 20px;
        }
        &__designation {
          font-size: 16px;
        }
        &__company-name {
          position: absolute;
          font-size: 15px;
          bottom: 112%;
          left: 62%;
          max-width: 175px;
          line-height: 1.5;
          margin-top: 0;
        }
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
      .slick-prev {
        right: 90px;
      }
      .slick-next {
        right: 40px;
      }
    }
  }
}
