@font-face {
  font-family: "poppinsbold";
  src: url("./stylesheets/fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("./stylesheets/fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinslight";
  src: url("./stylesheets/fonts/poppins-light-webfont.woff2") format("woff2"),
    url("./stylesheets/fonts/poppins-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsmedium";
  src: url("./stylesheets/fonts/poppins-medium-webfont.woff2") format("woff2"),
    url("./stylesheets/fonts/poppins-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("./stylesheets/fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("./stylesheets/fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinssemibold";
  src: url("./stylesheets/fonts/poppins-semibold-webfont.woff2") format("woff2"),
    url("./stylesheets/fonts/poppins-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gibsonbold";
  src: url("./stylesheets/fonts/gibson-bold-webfont.woff2") format("woff2"),
    url("./stylesheets/fonts/gibson-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gibsonregular";
  src: url("./stylesheets/fonts/gibson-regular-webfont.woff2") format("woff2"),
    url("./stylesheets/fonts/gibson-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content {
  flex: 1;
}
