input {
  &[type="text"],
  &[type="password"],
  &[type="email"],
  &[type="tel"],
  &[type="number"] {
    @extend .p;
    background: transparent;
    border: 1px solid #a8a8a8;
    width: 100%;
    height: 36px;
    line-height: 36px;
    padding: 4px 10px;
    margin: 10px 0;
    &:focus {
      outline: none;
    }
    @include media("<sm") {
      font-size: 14px !important;
    }
    @include media(">=sm") {
      height: 48px;
      line-height: 48px;
      padding: 6px 12px;
      margin: 14px 0;
    }
  }
}

.input-wrap {
  position: relative;
  .err {
    position: absolute;
    bottom: -4px;
    left: 0;
    font-size: 10px;
    color: $dark-orange;
    transition: 0.3s;
    @include media(">=sm") {
      font-size: 12px;
    }
  }
}

.select-input,
.cart__history-filter input {
  @extend .p;
  background: transparent;
  border: 1px solid #a8a8a8;
  width: 100%;
  height: 36px;
  line-height: 24px;
  padding: 6px 12px;
  margin: 14px 0;
  @include media(">=sm") {
    height: 48px;
    line-height: 2;
  }
  &:focus {
    outline: none;
  }
  appearance: none;
  background-color: transparent;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 96% center;
  @include media(">=sm") {
    background-size: 14px;
  }
}

.cart__history-filter input {
  background-size: 14px;
  background-position: 95% center;
  @include media(">=sm") {
    background-size: 16px;
  }
}

textarea {
  @extend .p;
  width: 100%;
  height: 220px;
  border: 1px solid #a8a8a8;
  padding: 10px;
  resize: none;
  &:focus {
    outline: none;
  }
}
