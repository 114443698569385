.signed-up {
  max-width: 700px;
  background-color: white;
  padding: 20px;
  text-align: center;
  @include media(">=sm") {
    padding: 60px;
  }
  &__wrap {
    margin: auto;
    .img {
      margin: auto;
      margin-bottom: 10px;
      width: 170px;
      > svg {
        width: 100%;
      }
      @include media(">=sm") {
        width: auto;
      }
    }
  }
}
