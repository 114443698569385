.topbar {
  box-shadow: 3px 2px 6px 0px #ececec8c;
  border-bottom: 1px solid #ececec;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 18px 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 7;
  img {
    max-width: 100%;
  }
  div[class^="col-"] {
    padding: 0;
    display: flex;
    align-items: center;
    span[class^="icon-"] {
      margin-right: 10px;
      font-size: 18px;
    }
  }
  &__nav {
    margin-left: 12px;
  }
  .brand-icon {
    text-align: right;
    &__img {
      width: 28px;
      height: 28px;
      background-color: $purple;
      border-radius: 50%;
      display: flex;
      margin-left: auto;
      img {
        width: 20px;
        margin: auto;
      }
      &.left-home {
        margin-left: 0;
      }
    }
  }
}
