.btn,
button {
  font-family: $gibson-bold;
  position: relative;
  z-index: 1;
  display: inline-block;
  outline: none;
  line-height: 1;
  will-change: color, border;
  text-align: center;
  font-size: 14px;
  padding: 1rem 2rem;
  text-decoration: none;
  &__log-cta {
    padding: 1.6rem 3.8rem !important;
    font-size: 20px !important;
  }
  @include media(">=sm") {
    padding: 1.2rem 3rem;
    font-size: 16px;
  }
  @include media(">=md") {
    padding: 1.6rem 3.8rem;
    font-size: 20px;
  }
  &--white {
    background-color: $white;
    color: $purple;
    border: 1px solid $white;
  }
  &--hollow {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: scaleX(0);
      will-change: transform;
      transform-origin: left;
      @include transition(all, 0.3s);
      background-color: $white;
    }
    @include media(">=md") {
      &:hover {
        color: $dark-orange;
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }
  &--hollow-orange {
    background-color: transparent;
    color: $dark-orange;
    border: 1px solid $dark-orange;
  }
  &--purple {
    background-color: $purple;
    color: $white;
    border: 1px solid $purple;
  }
  &--orange {
    background-color: transparent;
    color: $dark-orange;
    border: 1px solid $dark-orange;
    transition-property: all !important;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: scaleX(0);
      will-change: transform;
      transform-origin: left;
      @include transition(all, 0.3s);
      background-color: $dark-orange;
      background: linear-gradient($dark-orange, $orange);
    }
    @include media(">=md") {
      &:hover {
        color: $white;
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }

  &--block {
    width: 100% !important;
  }
}
