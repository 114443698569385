$poppins-bold: "poppinsbold";
$poppins-light: "poppinslight";
$poppins-medium: "poppinsmedium";
$poppins-regular: "poppinsregular";
$poppins-semibold: "poppinssemibold";
$gibson-bold: "gibsonbold";
$gibson-regular: "gibsonregular";

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $gibson-bold;
  font-weight: normal;
  color: $grey;
}

h1,
.h1 {
  font-size: 28px;
  line-height: 38px;
  @include media(">=sm") {
    font-size: 35px;
    line-height: 50px;
  }
  @include media(">=md") {
    font-size: 60px;
    line-height: 75px;
  }
}

h2,
.h2 {
  font-size: 20px;
  line-height: 30px;
  @include media(">=sm") {
    font-size: 25px;
    line-height: 35px;
  }
  @include media(">=md") {
    font-size: 50px;
    line-height: 65px;
  }
}

h3,
.h3 {
  font-size: 20px;
  line-height: 30px;
  @include media(">=sm") {
    font-size: 25px;
    line-height: 35px;
  }
  @include media(">=md") {
    font-size: 40px;
    line-height: 45px;
  }
}

h4,
.h4 {
  font-size: 16px;
  line-height: 24px;
  @include media(">=sm") {
    font-size: 16px;
    line-height: 24px;
  }
  @include media(">=md") {
    font-size: 30px;
    line-height: 45px;
  }
}

h5,
.h5 {
  font-size: 24px;
  line-height: 36px;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 26px;
}

p,
.p {
  color: $grey;
  font-family: $poppins-regular;
  font-size: 14px;
  line-height: 24px;
  @include media(">=md") {
    font-size: 18px;
    line-height: 26px;
  }
}

span,
dd,
dt {
  font-family: $poppins-regular;
}
