.popup {
  &__wrapper {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000040;
    z-index: 9;
    @include media("<=xs") {
      position: fixed;
      z-index: 5;
      padding-top: 62px;
      height: 100% !important;
      &-submitted {
        z-index: 9;
        padding-top: 0 !important;
      }
    }
    &-inner {
      position: relative;
      margin: auto;
      background: #fff;
      width: 90%;
      height: 95%;
      max-width: 955px;
      max-height: 480px;
      @include media(">=sm") {
        max-height: 544px;
      }
      @include media("<=xs") {
        width: 100%;
        height: 100%;
        max-height: unset;
        max-width: unset;
      }
      &-submitted {
        text-align: center;
        padding: 40px;
        height: auto;
        max-width: 710px;
        max-height: 480px;
        @include media(">=sm") {
          padding: 60px;
        }
        @include media("<=xs") {
          width: 90%;
        }
      }
    }
    &-close {
      cursor: pointer;
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
      img {
        width: 12px;
        height: 12px;
      }
      &-m {
        font-size: 12px;
        top: 1.6rem;
      }
      @include media(">=sm") {
        top: 2.2rem;
        right: 2.2rem;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    &-flex {
      display: flex;
      height: 100%;
      width: 100%;
      @include media("<=xs") {
        height: calc(100% - 48px);
      }
    }
    &-left {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $whitish;
      @include media("<=xs") {
        flex: 4;
      }
      .filter-by {
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        @include media(">=sm") {
          font-size: 14px;
        }
      }
      &-cat {
        overflow-y: auto;
      }
      button {
        color: #3b3b3b;
        font-family: $poppins-semibold;
        font-size: 12px;
        text-align: left;
        width: 100%;
        background-color: transparent;
        padding: 1.2rem;
        padding-left: 12px;
        border: none;
        border-left: 5px solid transparent;
        @include media(">=sm") {
          font-size: 16px;
          border-left-width: 9px;
          padding: 1.2rem 3rem;
          padding-left: 20px;
        }
        &:hover {
          background-color: #f1f1f1;
        }
        &.tab-link-active {
          cursor: default;
          border-left-color: $dark-orange;
          background-color: #fff;
          font-weight: normal !important;
        }
      }
      .clear-filter {
        font-family: $poppins-regular;
        text-align: center;
        text-transform: uppercase;
        color: $dark-orange;
        background-color: #efefef;
        @include media(">=md") {
          &:hover {
            cursor: pointer;
            background-color: #e4e4e4;
          }
        }
        @include media("<=xs") {
          display: none;
        }
      }
    }
    &-right {
      flex: 7;
      > div {
        height: 100%;
      }
    }
    &-items {
      padding: 12px;
      display: flex;
      flex-direction: column;
      height: 100%;
      @include media(">=sm") {
        padding: 20px 40px 20px 26px;
      }
      @include media(">=md") {
        padding: 20px 55px 20px 35px;
      }
      > p {
        margin: 0;
        margin-bottom: 4px;
        font-size: 12px;
        @include media(">=sm") {
          font-size: 16px;
          margin-bottom: 8px;
        }
        @include media("<=xs") {
          visibility: hidden;
        }
      }
      .filter_input {
        width: 100% !important;
      }
      &-wrap {
        margin-top: 16px;
        max-height: 100%;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        .inline-checkbox {
          padding: 5px;
          margin: 2px 0;
          width: 100%;
        }
        @include media(">=sm") {
          margin-top: 28px;
          .inline-checkbox {
            margin: 8px 0;
            flex: 1 0 50%;
            -webkit-flex: 1 0 50%;
          }
        }
      }
    }
    &-xs {
      background: white;
      box-shadow: 0px -3px 6px 0px #e0e0e080;
      height: 48px;
      z-index: 10;
      display: flex;
      button {
        flex: 1;
        height: 100%;
        background: white;
        color: $dark-orange;
        border: none;
        outline: none;
      }
    }
  }
}
