.signup-form {
  width: 100%;
  &__wrap {
    max-width: 820px;
    margin: 40px auto 90px;
  }
  &__ins {
    color: $grey-light;
    margin: 0;
    padding: 0 15px;
  }
  &__submit {
    margin: 24px auto 10px;
    text-align: center;
    .btn {
      margin: 10px;
    }
    @include media(">=sm") {
      margin: 40px auto 10px;
    }
  }
  &__login {
    text-align: center;
    a {
      text-decoration: none;
      strong {
        font-family: $poppins-bold;
        color: $purple;
      }
      &:hover {
        color: darken($grey, 10);
      }
    }
  }
}
