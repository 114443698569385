.c-profile__dropdown {
  position: absolute;
  right: -3px;
  top: 100%;
  min-width: 200px;
  z-index: 5;
  @include media(">=sm") {
    min-width: 243px;
  }
  @include media("<=xs") {
    min-width: 180px;
  }
  display: none;
  &.show {
    display: block !important;
  }
  .cd {
    &__wrap {
      background: white;
      // @include box-shadow;
      border: 1px solid #dedede;
      ul {
        list-style: none;
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
        li {
          background-color: white;
          border-top: 1px solid #ececec;
          > a {
            font-family: $poppins-medium;
            font-size: 14px;
            color: $purple;
            display: block;
            padding: 14px 12px;
            text-decoration: none;
            @include media(">=md") {
              font-size: 16px;
            }
          }
          &:hover {
            background-color: $whitish;
          }
          &:first-child,
          &:last-child {
            border-top: none;
          }
          &:last-child {
            > a {
              font-family: $poppins-bold;
            }
            background: #f7f7f7;
            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }
    }
  }
}
