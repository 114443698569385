.profile {
  &__list {
    // margin: 40px 0;
    background: #fafafa;
    min-height: 550px;
    &-container {
      margin: 0 auto;
      padding: 20px 0;
      display: flex;
      display: -webkit-flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      justify-content: space-between;
      @include media(">=sm") {
        padding: 35px 15px;
      }
      @include media(">=md") {
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      .rel-head {
        font-family: $poppins-bold;
        font-size: 18px;
        color: $purple;
        margin: 0 26px 10px;
        flex: 100%;
        @include media(">=sm") {
          font-size: 22px;
          margin: 0 0 16px;
        }
        @include media(">=md") {
          margin-bottom: 20px;
        }
      }
    }
  }
}
