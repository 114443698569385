.cart__history {
  &-filter {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    @include media("<sm") {
      flex: 1;
      &-e {
        margin-bottom: 0 !important;
      }
    }
    @include media(">=sm") {
      margin-right: 0;
      flex-direction: row;
    }
    .DayPickerInput {
      margin-right: 15px;
      input {
        margin: 6px 0;
        background-image: url("../images/calendar.svg");
        @include media("<=xs") {
          margin-bottom: 0;
        }
      }
    }
  }
}
