.contact {
  background-color: $dark-orange;
  background: linear-gradient(to bottom, #ff6200, #ec3c32 100%);
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    @include media(">=sm") {
      padding: 60px 0;
      flex-direction: row;
    }
    @include media(">=md") {
      padding: 100px 0;
    }
  }
  &__text {
    h2 {
      color: white;
      margin: 0;
      margin-bottom: 2rem;
      @include media(">=sm") {
        margin-bottom: 0;
      }
      @include media(">=md") {
        font-size: 48px;
      }
    }
  }
  &__btn {
    button {
      @include media(">=md") {
        font-size: 24px;
      }
    }
  }
}
