.login-form {
  padding: 40px 30px;
  margin: 16vh auto auto;
  background: $whitish;
  width: 100%;
  max-width: 620px;
  @include media(">=sm") {
    padding: 60px 30px;
    margin: 100px auto auto;
  }
  @include media(">=md") {
    max-width: 974px;
    margin: 40px auto auto;
  }
  &__wrap {
    max-width: 400px;
    margin: 20px auto;
    @include media("<=xs") {
      margin: 10px auto;
    }
    form {
      margin-bottom: 50px;
      @include media("<=xs") {
        margin-bottom: 35px;
      }
      .btn--block {
        margin: 16px 0 10px;
        @include media(">=sm") {
          margin: 25px 0 10px;
        }
      }
    }
    .forgot {
      text-align: center;
      &-head {
        margin-top: 0;
        font-family: $poppins-bold;
        font-size: 18px;
        margin-bottom: 10px;
        &-sent {
          margin-top: 30px;
          font-size: 14px;
        }
        @include media(">=sm") {
          font-size: 24px;
          margin-bottom: 10px;
          &-sent {
            margin-top: 45px;
            font-size: 20px;
          }
        }
      }
      &-hint {
        font-size: 14px;
        @include media(">=sm") {
          font-size: 16px;
        }
      }
      &-form {
        margin-top: 26px;
        margin-bottom: 8px;
        @include media(">=sm") {
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
      &-login {
        cursor: pointer;
        display: inline-block;
        margin: 0;
        color: $dark-orange;
        a {
          text-decoration: none;
          color: $dark-orange;
        }
      }
    }
  }
  &__help {
    display: block;
    text-align: right;
    cursor: pointer;
    &:hover {
      color: darken($grey, 10);
    }
  }
  &__signup {
    text-align: center;
    a {
      text-decoration: none;
      strong {
        font-family: $poppins-bold;
        color: $purple;
      }
      &:hover {
        color: darken($grey, 10);
      }
    }
  }
}
